<!--
    描述：登录页面
    时间：2021-03-26
    Created by 王帅
-->
<template>
	<div class="login-main">
		<!-- 头部信息 -->
		<div class="login-header">
			<span>合肥东部新中心建设管理办公室</span>
			<!-- <div class="login-logo">
                 <img class="logo-img" src="@/assets/images/logo_jk.png" alt="" />
            </div> -->
		</div>

		<!-- 中间banner -->
		<div class="login-banner">
			<div class="login-box">
				<el-form ref="loginForm"
						 :model="loginForm"
						 :rules="loginRules"
						 class="login-form"
						 autocomplete="on"
						 label-position="left"
						 label-width="0">
					<div class="title-container">合肥东部新中心项目管理系统</div>
					<!-- <div class="title-container">经开区审计管理系统</div> -->

					<el-form-item prop="username">
						<el-input ref="username"
								  v-model="loginForm.username"
								  placeholder="请输入用户名"
								  type="text"
								  clearable
								  tabindex="1"
								  @keyup.enter.native="handleLogin"></el-input>
					</el-form-item>

					<el-form-item prop="password">
						<el-input type="password"
								  show-password
								  placeholder="请输入密码"
								  v-model="loginForm.password"
								  tabindex="1"
								  clearable
								  @keyup.enter.native="handleLogin"></el-input>
					</el-form-item>

					<el-button :loading="btnLoading"
							   class="login-btn btn-blue"
							   @click.native.prevent="handleLogin">确认登录</el-button>
				</el-form>
			</div>
		</div>

		<!-- 底部信息 -->
		<div class="login-footer">
			<p class="footer-text">
				<span class="mr10">版权所有：鼎信数智技术集团股份有限公司</span>
				<span class="ml20 mr10">技术支持：安徽本末数据科技有限公司 </span>
				<a href="https://beian.miit.gov.cn/#/Integrated/index"
				   target="_blank"
				   class="ml20">
					版权所有：Copyright © 皖ICP备18011150号-4
				</a>
			</p>
		</div>

		<!-- 多企业选择弹窗 -->
		<el-dialog :visible.sync="companyDialog.visible"
				   width="800px"
				   :title="companyDialog.title"
				   :close-on-click-modal="false"
				   class="company-dialog company-management"
				   center>
			<p class="select-info">
				欢迎进入
				<span class="company-name">项目管理系统</span> ，请选择进入企业
			</p>
			<el-row v-for="(item, index) in companyList"
					:key="'company' + index"
					type="flex"
					justify="center"
					class="mb20">
				<el-col :span="12">
					<el-button class="w100 select-btn"
							   @click.prevent.stop="getStatus(item)">
						<svg-icon class="select-success mr10"
								  icon-class="dui"
								  v-show="item.finalResult === 1"></svg-icon>
						<svg-icon class="select-error mr10"
								  icon-class="gantanhao"
								  v-show="item.finalResult === 0"></svg-icon>
						{{ item.sname }}
					</el-button>
				</el-col>
			</el-row>
		</el-dialog>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { setItem, removeItem } from '@/utils/storage.js'
import {
	getTenants,
	getTenantAndLimits,
	selectValueByKey,
} from 'api/system/common'

export default {
	name: '',
	data() {
		return {
			loginForm: {
				username: '',
				password: '',
			},
			loginRules: {
				username: [
					{
						required: true,
						trigger: 'blur',
						message: '请输入用户名',
					},
				],
				password: [
					{
						required: true,
						trigger: 'blur',
						message: '请输入密码',
					},
				],
			},
			passwordType: 'password',
			capsTooltip: false, // 大写开启提示
			btnLoading: false,
			showDialog: false,
			redirect: undefined,
			otherQuery: {},
			companyDialog: {
				visible: false,
				title: '选择企业',
			},
			companyList: [], // 企业数组
		}
	},
	created() {
		let arr = document.querySelectorAll('body script')
		arr = [...arr]
		arr.forEach((x) => {
			if (x.src.includes('baidu')) {
				document.body.removeChild(x)
			}
		})
	},
	methods: {
		...mapActions('menu', [
			'setMenu',
			'setBreadList',
			'setRouteList',
			'setTabsList',
		]),
		...mapActions('user', [
			'loginIn',
			'saveTenantId',
			'checkedMaintenanceRole',
		]),
		// 检查是否开启了大写
		checkCapslock(e) {
			const { key } = e
			this.capsTooltip =
				key && key.length === 1 && key >= 'A' && key <= 'Z'
		},
		...mapActions('user', ['loginIn', 'mobileLogin']),
		// 登录
		handleLogin() {
			this.btnLoading = true
			// 登录接口
			this.loginIn({
				username: this.loginForm.username,
				password: this.loginForm.password,
			})
				.then((res) => {
					this.btnLoading = false
					removeItem('tenantId')
					removeItem('limitId')
					if (res.operationCode != 1 || res.msg == 'faild') {
						this.$message.error(res.data)
						return
					}
					let roleType = res.data.roleType
					if (roleType === 1) {
						this.$router.push({ path: '/Select' })
						this._checkedMaintenanceRole(roleType) // 刷新缓存
					} else {
						this._checkedMaintenanceRole()
					}
					selectValueByKey({ key: 'baiduMap' }).then((res) => {
						let script = document.createElement('script')
						script.src =
							'https://api.map.baidu.com/api?v=2.0&ak=' +
							res.data[0].parameterValue +
							'&callback=initialize'
						document.body.appendChild(script)
					})
				})
				.catch(() => {
					this.btnLoading = false
				})
		},

		// 获取其它参数
		getOtherQuery(query) {
			return Object.keys(query).reduce((acc, cur) => {
				if (cur !== 'redirect') {
					acc[cur] = query[cur]
				}
				return acc
			}, {})
		},

		getStatus(data) {
			setItem('tenantId', data.id)
			this.saveTenantId(data.id)
			// 设置公司名称
			setItem('companyName', data.sname)
			if (this.routeQuery.type === '2') {
				this.$router.push({ path: '/Select' })
			} else {
				if (data.limitList.length === 1) {
					setItem('limitId', data.limitList[0].id)
					this.$router.push({ path: '/Select' })
				} else {
					this.$router.push({
						name: 'choseMain',
						params: { data: data },
					})
				}
			}
		},

		// 获取租户
		getTenants() {
			getTenants().then((res) => {
				// console.log(res.data)
				this.companyList = res.data
				if (this.companyList.length === 1) {
					// 设置公司名称
					setItem('companyName', this.companyList[0].sname)
					setItem('tenantId', this.companyList[0].id)
					this.saveTenantId(this.companyList[0].id)
					this.$router.push({ path: '/Select' })
				} else {
					this.companyDialog.visible = true
				}
			})
		},

		// 获取主体
		getTenantAndLimits(roleType) {
			getTenantAndLimits().then((res) => {
				this.companyList = res.data
				if (this.companyList.length === 1) {
					// 设置公司名称
					setItem('companyName', this.companyList[0].sname)
					setItem('tenantId', this.companyList[0].id)
					this.saveTenantId(this.companyList[0].id)
					if (this.companyList[0].limitList.length === 1) {
						setItem('limitId', this.companyList[0].limitList[0].id)
						this.$router.push({ path: '/Select' })
					} else {
						this.$router.push({
							name: 'choseMain',
							params: { data: this.companyList[0] },
						})
					}
					return
				}
				// 管理员没有企业
				if (roleType === 1) {
					return
				}
				this.companyDialog.visible = true
			})
		},

		// 判断运维角色
		_checkedMaintenanceRole(roleType) {
			this.checkedMaintenanceRole().then((res) => {
				// 是，则不用选择主体
				if (res == 1) {
					setItem('companyName', '')
					setItem('tenantId', '')
					setItem('limitId', '')
					this.saveTenantId('')
					this.$router.push({ path: '/Select' })
				} else {
					this.getTenantAndLimits(roleType)
				}
			})
		},
	},
	mounted() {
		// 初始聚焦
		if (this.loginForm.username === '') {
			this.$refs.username.focus()
		} else if (this.loginForm.password === '') {
			this.$refs.password.focus()
		}

		if (this.routeQuery.type && this.routeQuery.type !== 'undefined') {
			setItem('type', this.routeQuery.type)
		}
	},
	computed: {
		// 路由列表
		routerList() {
			return this.$router.options.routes
		},
		...mapState('user', {
			userInfo: (state) => state.userInfo,
		}),
		//路由参数
		routeQuery() {
			this.$route.query.type &&
			this.$route.query.type !== 'undefined' &&
			this.$route.query.type !== 'null'
				? setItem('type', this.$route.query.type)
				: ''
			if (!this.$route.query.type) {
				removeItem('type')
			}
			return this.$route.query
		},
	},
}
</script>

<style scoped lang="less">
.login-main {
	height: 100%;
	background-color: #fff;

	.login-header {
		height: 10vh;
		box-shadow: 0px 0px 10px 0px rgba(57, 142, 141, 0.5);
		padding: 0 13%;
		font-size: 2.5vh;
		color: #000;
		display: flex;
		align-items: center;

		.login-logo {
			// height: 50%;
			height: 76px;
			margin-right: 30px;
		}

		img {
			height: 100%;
		}
	}

	.login-banner {
		// height: 44vh;
		height: 600px;
		background: url('../../assets/images/bg_dx.jpg') no-repeat;
		// background: url("../../assets/images/banner-bg.jpg") no-repeat;
		// background: url("../../assets/images/login_banner_bg.jpg") no-repeat;
		background-size: 100% 100%;
		position: relative;

		.login-box {
			position: absolute;
			top: 15%;
			right: 19%;
			width: 22%;
			height: 65%;
			min-width: 320px;
			padding: 10px;
			background: #ffffff4d;
			box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
			border-radius: 10px;
		}

		.login-form {
			height: 100%;
			padding: 0 7.5%;
			background-color: #fff;
			font-size: 2.3vh;
			overflow: hidden;
			overflow-y: auto;
		}

		.title-container {
			height: 28%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.login-btn {
			width: 100%;
			height: 40px;
			padding: 0;
		}
	}

	@media screen and (max-width: 1366px) {
		.login-banner {
			height: 460px !important;
		}
		.login-box {
			top: 10% !important;
			height: 80% !important;
		}
	}

	.login-footer {
		font-size: 14px;
		color: #999;
		text-align: center;
		padding-top: 30px;
	}
}

.company-dialog {
	/deep/ .el-dialog__body {
		padding-bottom: 60px;
	}
}

.select-info {
	line-height: 60px;
	font-size: 16px; // font-weight: 600;
	text-align: center;
	padding-left: 20px;
	margin-bottom: 10px;
	.company-name {
		color: #2671cc;
	}
}

.select-btn {
	text-align: left;
	padding-left: 60px;
	background-color: #f5f7fb;
	font-weight: normal;
	border: none;
	&:hover {
		// box-shadow: 2px 2px 2px 2px #F5F7FB;
		// box-shadow: 0px 0px 4px 2px #69c4fd;
		// box-shadow: 3px 3px 0px 0px #dcdcdc;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		color: unset;
	}
}

.select-success {
	color: #39c098;
}

.select-error {
	color: #e6a23c;
}
</style>
